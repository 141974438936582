const syncProjection = ()=>import("../views/copybook/syncProjection");
const copyBook =()=>import("../views/copybook/copybook")
const createWord = ()=>import("../views/copybook/createWord")
const doohickey =()=>import("../views/doohickey/doohickey")
const videoProjection = ()=>import("../views/copybook/videoProjection");


export default [
    {
        path:"/syncProjection",
        name:"syncProjection",
        meta: {
            title: '同步投影',
            keepAlive: false
        },
        component:syncProjection
    },
    {
        path:'/copybook',
        name:"copybook",
        meta: {
            title: '我的字帖',
            keepAlive: false
        },
        component:copyBook
    },
    {
        path:'/createWord',
        name:'createWord',
        meta:{
            title:'创建字帖',
            keepAlive:false
        },
        component:createWord
    },
    {
        path:'/doohickey',
        name:'doohickey',
        meta:{
            title:'窍门',
            keepAlive:false
        },
        component:doohickey
    },
    {
        path:"/videoProjection",
        name:"videoProjection",
        meta: {
            title: '视频投影',
            keepAlive: false
        },
        component:videoProjection
    },
]