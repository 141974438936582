const dataScreenMain = () =>
  import("../views/DataScreen/dataScreenMain.vue")
const AreaData = () =>
  import("../views/DataScreen/areaData.vue")
const ClassData = () =>
  import("../views/DataScreen/classData.vue")
  const SchoolData = () =>
  import("../views/DataScreen/schoolData.vue")
  const nationwideData = () =>
  import("../views/DataScreen/nationwideData.vue")
  const ProvinceData = () =>
  import("../views/DataScreen/provinceCityAreaData.vue")
  const CityData = () =>
  import("../views/DataScreen/provinceCityAreaData.vue")
  const NewAreaData = () =>
  import("../views/DataScreen/provinceCityAreaData.vue")
export default [
  {
    path: '/dataScreenMain',
    name: 'dataScreenMain',
    // meta: {
    //   title: '区域',
    //   keepAlive: false
    // },
    redirect: "/dataScreenMain/areaData",
    component: dataScreenMain,
    children: [
      {
        path: 'areaData',
        name: 'areaData',
        meta: {
          title: '区域',
          keepAlive: false
        },
        component: AreaData,
      },
      {
        path: 'classData',
        name: 'classData',
        meta: {
          title: '班级',
          keepAlive: false
        },
        component: ClassData,
      },
      {
        path: 'schoolData',
        name: 'schoolData',
        meta: {
          title: '学校',
          keepAlive: false
        },
        component: SchoolData,
      }
    ]
  },
  {
    path: '/nationwideData',
    name: 'nationwideData',
    meta: {
      title: '全国',
      keepAlive: false
    },
    component: nationwideData,
  },
  {
    path: '/ProvinceData',
    name: 'ProvinceData',
    meta: {
      title: '省级',
      keepAlive: false
    },
    component: ProvinceData,
  },
  {
    path: '/CityData',
    name: 'CityData',
    meta: {
      title: '市级',
      keepAlive: false
    },
    component: CityData,
  },
  {
    path: '/NewAreaData',
    name: 'NewAreaData',
    meta: {
      title: '区县',
      keepAlive: false
    },
    component: NewAreaData,
  }
]